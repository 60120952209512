@import './reset';
@import './mixin';
@import './setting';

.header_client {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  // padding: 1.8rem 1.5rem !important;
  padding: 0.8rem 1.5rem !important;
  // background-color: #041e42 !important;
  background-color: #fff !important;
  border-bottom: 1px solid #ebebeb;
  @include mq_min_768 {
    position: static;
  }
  a {
    display: flex;
    justify-content: center;
    @include mq_min_768 {
      max-width: 41rem;
      justify-content: flex-start;
    }
  }

  h2 {
    @include flexbox(flex, center, center, nowrap);
    font-size: 1.4rem;
    font-weight: 500;
    font-family: Proxima, sans-serif;
    color: #fff;

    @include mq_min_768 {
      justify-content: flex-start;
      font-size: 1.6rem;
    }
  }

  img {
    max-width: 8rem;
    margin-right: 0.8rem;
    padding: 1rem;
    object-fit: cover;
    background-color: #fff;
    @include mq_min_768 {
      max-width: 10rem;
    }
  }
}

.header_client_rawlings {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 1.8rem 1.5rem !important;
  background-color: #041e42 !important;
  // background-color: #fff !important;
  // border-bottom: 1px solid #ebebeb;
  @include mq_min_768 {
    position: static;
  }
  a {
    display: flex;
    justify-content: center;
    @include mq_min_768 {
      max-width: 35rem;
    }
  }

  h2 {
    @include flexbox(flex, center, center, nowrap);
    font-size: 1.4rem;
    font-weight: 500;
    font-family: Proxima, sans-serif;
    color: #fff;

    @include mq_min_768 {
      justify-content: flex-start;
      font-size: 1.6rem;
    }
  }

  img {
    max-width: 3.6rem;
    margin-right: 0.4rem;
    object-fit: fill;
  }
}

.header_client_print {
  // position: fixed;
  // top: 0;
  // right: 0;
  width: 100%;
  // z-index: 999;
  // padding: 1.8rem 1.5rem !important;
  padding: 0.8rem 1.5rem !important;
  // background-color: #041e42 !important;
  background-color: #fff !important;
  border-bottom: 1px solid #ebebeb;
  @include mq_min_768 {
    position: static;
  }
  a {
    display: flex;
    justify-content: center;
    @include mq_min_768 {
      max-width: 41rem;
      justify-content: flex-start;
    }
  }

  h2 {
    @include flexbox(flex, center, center, nowrap);
    font-size: 1.4rem;
    font-weight: 500;
    font-family: Proxima, sans-serif;
    color: #fff;

    @include mq_min_768 {
      justify-content: flex-start;
      font-size: 1.6rem;
    }
  }

  img {
    max-width: 8rem;
    margin-right: 0.8rem;
    padding: 1rem;
    object-fit: cover;
    background-color: #fff;
    @include mq_min_768 {
      max-width: 10rem;
    }
  }
}

.main {
  background-color: #f1f1f1;

  & > .container {
    .title-product {
      @include flexbox(flex, space-between, center, nowrap);
      padding: 2rem 0;

      p {
        font-weight: normal;

        .price {
          display: block;
          margin-top: 0.5rem;
        }
      }

      .function {
        .export-pdf {
          margin-top: 0.5rem;
        }
      }
    }

    .preview-product {
      position: fixed;
      top: 12rem;
      width: 100%;
      margin: 0 auto;
      background: #e4e4e4;
      z-index: 999;
      transition: all 0.3s ease;
      user-select: none;

      @include mq_min_768 {
        position: absolute;
        top: 0;
        left: 40%;
        max-width: 35rem;
        padding-top: 2.5rem;
        transform: translateX(-50%);
        background: transparent;
      }
      @include mq_min_1024 {
        max-width: 45rem;
      }
      @include mq_min_1200 {
        left: 50%;
        max-width: 52rem;
      }
      // .slick-slide {
      //   @media (min-width: 767px) and (max-width: 1024px) {
      //     padding: 40px;
      //   }
      // }

      .wrap-image {
        position: relative;
        min-width: 22rem;
        min-height: 22rem;
        max-width: 22rem;
        max-height: 22rem;
        cursor: pointer;

        left: 50%;
        transform: translateX(-50%);
        @include mq_min_768 {
          min-width: 28rem;
          min-height: 28rem;
          max-width: 28rem;
          max-height: 28rem;
        }
        @include mq_min_1024 {
          min-width: 45rem;
          min-height: 45rem;
          max-width: 45rem;
          max-height: 45rem;
        }
        @include mq_min_1200 {
          min-width: 52rem;
          min-height: 52rem;
          max-width: 52rem;
          max-height: 52rem;
        }

        .img-slide {
          position: absolute;
          margin: 0 auto;
          // min-width: 30rem;
          // max-width: 30rem;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          @include mq_min_768 {
            left: -8rem;
            min-width: 100%;
            max-width: 100%;
          }
          @include mq_min_1024 {
            left: 0;
          }
        }
      }

      .btn-control-slide {
        display: none !important;
      }

      .btn-previous {
        left: -10rem;
      }

      .btn-next {
        right: -10rem;
      }

      .slick-dots {
        padding-bottom: 1rem;
        @include flexbox(flex, space-between, center, nowrap);
        position: static;
        @include mq_min_768 {
          padding-bottom: 0;
        }
      }

      .slick-dots li {
        position: static;
        width: auto;
        height: auto;
        margin: 0;
      }

      .slick-dots li button {
        width: 6rem;
        height: 0.5rem;
        // margin: 2.5rem 0 3rem;
        padding: 0;
        background-color: #d5d5d5;
        border-radius: 2.5rem;
        border: none;
        text-indent: -9999px;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.4s ease;

        &::before {
          display: none;
        }

        @include mq_min_768 {
          margin-bottom: 0;
        }
      }

      .slick-dots .slick-active button {
        background-color: #757575;
      }

      .slick-arrow {
        display: none !important;
      }

      .slick-slide div {
        outline: none;
      }
    }
  }

  .list-category {
    padding: 3rem 0 5rem;
    background-color: #fff;

    .container {
      .category-title {
        @include flexbox(flex, space-between, center, nowrap);
        text-align: center;

        @include mq_min_768 {
          margin: 0 auto 0 10.8rem;
        }

        .select-category {
          position: relative;
          max-width: 20rem;
          margin: 0 auto;

          p {
            font-weight: 500;

            span {
              margin-left: 2rem;
            }
          }

          .btn-control-category {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }

          .btn-previous-cate {
            left: -5rem;
          }

          .btn-next-cate {
            right: -5rem;
          }
        }
      }

      .list-category-item {
        @include flexbox(flex, space-between, normal, nowrap);
        padding: 3rem 2rem;
        overflow-x: auto;

        .category-item {
          position: relative;
          max-width: 7rem;
          border-radius: 5px;
          transition: 0.3s all ease;

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          }

          img {
            max-width: 7rem;

            @include mq_min_1024 {
              max-width: 8rem;
            }
          }

          p {
            text-align: center;
          }

          cursor: pointer;

          @include mq_min_1024 {
            max-width: 8rem;
          }

          &:not(:first-child) {
            margin-left: 1rem;
          }
        }

        .active {
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          transition: all 0.3s ease;

          &::after {
            position: absolute;
            content: '✓';
            top: -5px;
            right: -5px;
            z-index: 9;
            font-size: 20px;
            color: #000;
          }
        }
      }
    }
  }
}

.list-btn-home {
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  @include mq_min_1024 {
    display: block;
    margin: 9rem auto 8rem;
  }
}

.product-description {
  max-width: 60rem;
  margin: 36.5rem auto 0;
  font-size: 1.2rem;
  line-height: 2.2;
  text-align: center;
  background-color: #e4e4e4;
  p {
    @include flexbox(flex, center, center, nowrap);
    padding: 1rem 0;
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    svg {
      margin-left: 0.5rem;
      font-size: 1rem;
    }
  }
}

.name-font {
  margin: 2.5rem auto 0;
  padding: 2rem 0 1rem;
  border-top: 2px dotted #d6d6d6;
}

.btn-save-text {
  margin: 1.5rem 0;
  padding: 0 2.5rem;
  font-weight: 500;
  &:enabled {
    background-color: #3d3d3d !important;
  }
}

.custom-btn {
  max-width: 8rem;
  min-width: 8rem;
  height: 3.8rem;
  font-size: 1.2rem;
  @include mq_min_1024 {
    max-width: 16rem;
    min-width: 16rem;
    height: 4rem;
    font-size: 1.4rem;
  }
}

.footer {
  position: relative;
  width: 100%;
  height: 22rem;
  background-color: #292929;
  // background-image: url('../../../assets/images/image-backtop.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .link-footer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    a {
      @include font(1.2rem, 500, 1.8);
      color: #fff;
      padding: 0.5rem 1rem;
      border: 1px solid #fff;
      transition: all 0.3s ease;
      &:last-child {
        margin-left: 1rem;
      }
      &:hover {
        color: #000;
        background-color: #fff;
      }
      @include mq_min_768 {
        font-size: 1.5rem;
        padding: 0.5rem 1.5rem;
      }
    }
  }
  .btn-back-top {
    cursor: pointer;
    p {
      display: none;
      @include mq_min_768 {
        display: block;
      }
    }
    p,
    .icon-arrow {
      position: absolute;
      right: 15%;
      transform: translate(50%, -50%);
      font-weight: 600;
      @include mq_min_768 {
        right: 8%;
      }
    }
    p,
    .icon-arrow {
      top: 65%;
      color: #fff;
    }
    .icon-arrow {
      top: 50%;
      padding: 1rem;
      border-radius: 50%;
      color: #000;
      background-color: #fff;
      font-size: 15px;
      transition: all 0.3s ease;
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}

.rowtest {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
  padding: 20px;
}

.title-order {
  text-align: center;
  padding: 2rem;
  font-size: 2rem;
}

.bdr {
  border-right: 1px #cccccc solid;
}

.bdl {
  border-left: 1px #cccccc solid;
}

.pt-3 {
  padding-top: 3%;
}

.h-30 {
  height: 30px;
}

.icon-leftout {
  background: #fff !important;
  color: #000 !important;
  border: 1px #f1f1f1 solid;
}

.border {
  border: 1px #707070 solid;
}

.title-pc1-4 {
  padding-top: 4rem;
  font-size: 4rem;
  text-align: center;
  font-weight: 100;

  @media (max-width: 1024px) {
    font-size: 2rem;
  }
}

.title-small {
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }
}

.pattern {
  font-weight: bold;
}

.backgroundpc1-4 {
  padding-top: 6rem;
  @include mq_min_768 {
    padding-top: 0;
  }
  background: radial-gradient(ellipse at center, #fcfcfc 0%, #cdcdcd 100%);
}

.productspc1-4 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 260px);

  @media screen and (max-width: 767px) {
    display: block;
  }

  .pc1-4-left {
    cursor: pointer;
    padding: 80px 0;
    width: 50%;

    @media screen and (max-width: 767px) {
      width: 100% !important;
      padding: 50px 0;
    }

    .img-pc1-4 {
      img {
        width: 100%;
      }
    }

    .number-pc1-4 {
      text-align: center;
      padding-top: 50px;

      h4 {
        @media screen and (max-width: 767px) {
          font-size: 2rem;
        }

        font-size: 4rem;
        font-weight: 400;
      }
    }

    .text-pc1-4-left {
      width: 100%;
      text-align: center;

      @media screen and (max-width: 767px) {
        padding: 0;
      }

      h5 {
        @media screen and (max-width: 767px) {
          font-size: 1.5rem;
        }

        font-size: 2rem;
      }
    }

    .text-pc1-4-right {
      width: 100%;
      padding-left: 10%;
      padding-right: 10%;

      h5 {
        @media screen and (max-width: 767px) {
          font-size: 1.5rem;
        }

        color: #777777;
        font-size: 2rem;
      }
    }
  }
}

.tableInfor {
  padding-right: 10px;
  width: 50%;
  font-size: 15px;
  font-weight: 500;

  th {
    height: 56px;
    border: none !important;
  }

  td {
    height: 56px;
    border: none !important;
  }
}

.ant-layout-sider {
  background: none !important;
}

.tableInforRight {
  padding-left: 10px;
  width: 50%;
  font-size: 15px;
  font-weight: 500;

  th {
    height: 56px;
    border: none !important;
  }

  td {
    height: 56px;
    border: none !important;
  }
}

.tableSaveProduct {
  margin-top: 20px;
  background: #ffffff;
  font-size: 15px;
  font-weight: 500;
}

.screen_bg {
  min-height: calc(100vh - 60px);
  background: url('../../images/image_bg_sp.png') no-repeat center;
  background-size: cover;
  text-align: center;

  @include mq_min_768 {
    background: url('../../images/image_bg.png') no-repeat center;
    background-size: cover;
  }

  @include mq_min_576 {
    min-height: calc(100vh - 57px);
  }

  @include mq_max_576 {
    background: url('../../images/image_bg.png') no-repeat center;
    background-size: cover;
    min-height: calc(100vh - 57px);
  }

  .bg_main {
    margin-top: 13.9rem;

    @media (min-width: 576px) and (max-width: 767px) {
      margin-top: 5rem;
    }

    @include mq_min_768 {
      margin-top: 10.5rem;
    }

    .title_main {
      margin-bottom: 4px;
      font-size: 4rem;
      font-weight: bold;
      color: $cl-white;
      line-height: 4.9rem;

      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 3rem;
      }

      @include mq_min_768 {
        font-size: 8rem;
        line-height: 9.5rem;
      }
    }

    .suptitle_main {
      font-size: 4rem;
      font-weight: 500;
      line-height: 5.6rem;
      color: $cl-white;
      text-decoration: underline;

      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 3rem;
      }

      @include mq_max_576 {
        font-size: 2rem;
      }
    }
  }

  .footer_screen {
    bottom: 15px;
    width: 100%;

    .warning {
      margin-top: 12.3rem;
      color: $cl-white;

      @include mq_min_768 {
        margin-top: 8.75rem;
      }

      p {
        font-size: 1.2rem;
        line-height: 2.2rem;
        margin-bottom: 4px;
        font-weight: bold;
        text-decoration: underline;

        @include mq_min_576 {
          font-size: 1.4rem;
        }

        @include mq_min_768 {
          font-size: 1.6rem;
        }
      }

      .wrap-text-end {
        margin-top: 6rem;

        h4 {
          font-size: 2rem;
          font-weight: bold;
          font-family: 'Noto Serif Toto', serif;
          span {
            font-size: 1rem;

            @include mq_min_768 {
              font-size: 1.6rem;
            }
          }
        }

        h5 {
          padding: 0.5rem 0 1rem;
          @include font(0.7rem, bold, 1.2);

          @include mq_min_768 {
            font-size: 1rem;
            padding-top: 2rem;
          }
        }
      }
    }
  }

  & > .container {
    &::after {
      display: none;
    }
  }
}

.screen_txt,
.screen_option {
  min-height: calc(100vh - 60px);

  h1 {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    line-height: 4.2rem;
    color: $cl-black;

    @include mq_max_576 {
      font-size: 1.6rem;
    }
  }

  .txt_main {
    padding: 4rem 0 7rem;

    @include mq_max_576 {
      padding: 3rem 1.5rem;
    }

    .block_txt {
      font-weight: bold;
      margin: 4.7rem 0 7.8rem;

      h2 {
        font-size: 2rem;
        line-height: 28px;
        margin-bottom: 1.9rem;

        @include mq_max_576 {
          font-size: 1.2rem;
          margin-bottom: 1.7rem;
        }
      }

      p {
        font-size: 1.4rem;
        margin: 0;
        line-height: 1.8rem;

        @include mq_max_576 {
          font-size: 1rem;
        }
      }

      ul {
        margin-top: 2rem;
        font-size: 1.4rem;

        @include mq_max_576 {
          font-size: 1rem;
        }
      }
    }
  }

  .txt_option {
    padding: 75px 0;

    .block_option_detail {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      min-height: 290px;

      @include mq_max_576 {
        display: block;
        row-gap: 40px;
        padding: 60px 0;
        min-height: auto;
      }

      .option_detail {
        text-align: center;
        width: 25%;

        @include mq_max_576 {
          height: auto;
          margin-bottom: 3rem;
          width: 100%;
        }

        span {
          font-size: 190%;
          font-weight: bold;
          line-height: 3.7rem;
          color: $cl-black;
          cursor: pointer;
          position: relative;

          @include mq_max_576 {
            font-size: 2rem;
            line-height: 2.5rem;
          }

          img {
            @include mq_max_576 {
              width: 150px;
            }
          }

          &.text-tag {
            &.active {
              transition: all 0.3s ease;
              color: #50b23c;
              &::after {
                position: absolute;
                content: '✓';
                top: -20px;
                right: -30px;
                z-index: 9;
                font-size: 35px;
                color: #50b23c;
              }
            }
          }
        }

        ul {
          li {
            display: inline-block;
            width: 33%;
            margin-bottom: 90px;

            @include mq_max_576 {
              display: block;
              width: 100%;
              margin-bottom: 3rem;
            }
          }
        }
      }

      a {
        width: 100%;
      }
    }
  }
}

.new_index {
  .container {
    max-width: 100%;
    .setting_product {
      position: relative;
      @include mq_min_768 {
        @include flexbox(flex, space-between, normal, nowrap);
      }
      .text-product {
        position: fixed;
        top: 5.247rem;
        left: 0;
        right: 0;
        width: 100%;
        padding: 2rem;
        @include font(1.4rem, normal, 1.7);
        @include flexbox(flex, space-between, normal, nowrap);
        background: #e4e4e4;
        z-index: 999;
        @include mq_min_768 {
          position: static;
          padding: 2.5rem 6rem 0;
          flex-direction: column;
          background: transparent;
          margin-bottom: 4rem;
        }
        span {
          display: block;
        }
        .price-product {
          @include font(2rem, 500, 1.2);
        }
        .block-note {
          display: none;
          @include mq_min_768 {
            display: block;
            margin-top: auto;
            @include font(1.4rem, 400, 1.8);
          }
          span {
            display: block;
            font-weight: 500;
          }
          p {
            @include mq_min_768 {
              margin-top: 0.5rem;
              font-size: 1.3rem;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
      }
      .list-detail-number {
        .btn_main {
          border-radius: 30px;
          border: none;
          cursor: pointer;
          transition: 0.2s ease all;
        }
        .btn-number {
          width: 80%;
          height: 4rem;
          margin: 1rem;
          border: 1px #707070 solid !important;
        }
      }
      .btn_main {
        border-radius: 30px;
        border: none;
        cursor: pointer;
        transition: 0.2s ease all;
      }
      .option-product {
        background: $cl-white;
        text-align: center;
        transition: all 0.3s ease;
        .option-product-header {
          position: relative;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 767px) {
            display: none;
          }
          img {
            position: absolute;
            right: 13px;
            top: 50%;
            transform: translate(0, -50%);
            cursor: pointer;
          }
          span {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            font-size: 2.4rem;
            color: #656565;
            cursor: pointer;
          }
        }
        .option-product-main {
          padding: 9.8rem 0 4rem;
          @include mq_min_768 {
            width: 32.6rem;
            margin-top: 0;
          }
          @include mq_min_768 {
            padding-top: 9rem;
          }

          @include mq_max_576 {
            padding-bottom: 5rem;
          }
          .pagination-product {
            margin: -75px 0 15px;
            position: relative;
            @include mq_max_576 {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 2.2rem;
            }
            span {
              @include mq_max_576 {
                margin-left: 1.5rem;
              }
            }
            .icon-next {
              position: absolute;
              top: 50%;
              right: 15px;
              padding: 1.5rem;
              transform: translateY(-50%);
              cursor: pointer;
              user-select: none;
              @include mq_min_1024 {
                right: 5px;
              }
            }
            .icon-pre {
              position: absolute;
              top: 50%;
              left: 15px;
              padding: 1.5rem;
              transform: translateY(-50%) rotate(180deg);
              cursor: pointer;
              user-select: none;
              @include mq_min_1024 {
                left: 5px;
              }
            }
            .info-item {
              @include flexbox(flex, center, center, nowrap);
              @include mq_min_768 {
                display: block;
              }
              .wrapper-name-style {
                @include flexbox(flex, center, center, nowrap);
                @include mq_min_768 {
                  display: block;
                  width: 16rem;
                  margin: 0 auto;
                }
                @include mq_min_1024 {
                  width: 18rem;
                }
                padding: 0.5rem 2.5rem;
                background-color: #f1f1f1;
                border-radius: 30px;
                cursor: pointer;
                user-select: none;
                p {
                  max-width: 13rem;
                  margin: 0 auto;
                  text-align: center;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  white-space: nowrap;
                }
              }
            }
          }
          ul {
            .list_category {
              font-size: 1.4rem;
              font-weight: normal;
              line-height: 2rem;
              margin-bottom: 0.9rem;
              cursor: pointer;
              position: relative;
              display: inline-block;

              &.active {
                transition: all 0.3s ease;

                &::after {
                  position: absolute;
                  content: url(../../images/icon-check.svg);
                  top: -2px;
                  right: -22px;
                  z-index: 9;
                  font-size: 20px;
                  color: #000;
                }
              }
            }
          }
          .box-select {
            text-align: -webkit-center;
            @include mq_min_768 {
              height: 51rem;
              padding: 1rem 0 1.5rem;
              border-top: 1px solid #d9d9d9;
              border-bottom: 1px solid #d9d9d9;
              overflow-y: auto;
            }
            .list-part {
              padding-bottom: 1.5rem;
              @include mq_min_768 {
                padding-bottom: 0;
              }
              @media (max-width: 767px) {
                display: flex;
                height: auto !important;
                overflow-x: scroll !important;
                width: calc(2rem * 18);
              }

              .category-item {
                display: inline-block;
                position: relative;
                width: calc(50% - 2.5rem);
                padding: 0.8rem 1.2rem;
                margin: 0.5rem 0.8rem;
                border-radius: 30px;
                border: 1px solid #f1f1f1;
                cursor: pointer;
                @include mq_min_768 {
                  padding: 1.2rem;
                  border-radius: 8px;
                }
                @media (max-width: 767px) {
                  display: flex;
                  position: relative;
                  min-width: calc(100% / 5);
                  margin: 0 1rem;
                  cursor: pointer;
                  flex-direction: column;
                }

                img {
                  display: none;
                  width: 8rem;
                  margin: auto auto;
                  background: transparent !important;
                  @include mq_min_768 {
                    display: block;
                  }
                }

                p {
                  text-overflow: ellipsis;
                  overflow: hidden;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  white-space: nowrap;
                  @include mq_min_768 {
                    margin-top: 0.8rem;
                  }
                }
              }

              .active {
                background: #f1f1f1;
                transition: all 0.3s ease;

                &::after {
                  position: absolute;
                  content: url(../../images/icon-check.svg);
                  // content: '✓';
                  top: -10px;
                  right: 0;
                  z-index: 9;
                  font-size: 20px;
                  color: #000;
                  @include mq_min_1024 {
                    top: -5px;
                    right: 5px;
                  }
                }
              }
              // .recommend {
              //   &::before {
              //     position: absolute;
              //     content: 'おススメ';
              //     right: -30px;
              //     bottom: 10px;
              //     padding: 0.1rem 3rem;
              //     z-index: 9;
              //     font-size: 10px;
              //     color: #fff;
              //     background-color: #ff5858;
              //     transform: rotate(320deg);
              //   }
              // }
              .recommend {
                &::before {
                  position: absolute;
                  content: '';
                  background-image: url('../../images/icon-star.png');
                  top: 3px;
                  left: 2px;
                  width: 2rem;
                  height: 2rem;
                  background-size: 2rem 2rem;
                  z-index: 9;
                  @include mq_min_768 {
                    font-size: 22px;
                  }
                  @include mq_min_1024 {
                    top: 5px;
                    left: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
//====================================================

.function-sp {
  @include flexbox(flex, center, center, nowrap);

  .btn {
    &:last-child {
      margin-left: 1.5rem;
    }
  }

  .active {
    color: #fff;
    background-color: #000;
  }
}

.ant-modal {
  width: 100vw;
  max-width: 100vw;
  .ant-modal-content {
    &::before {
      position: absolute;
      content: '';
      left: 50%;
      transform: translateX(-50%);
      top: 1.5rem;
      width: 3rem;
      height: 0.3rem;
      border-radius: 2.5rem;
      background-color: #d0d0d0;
    }

    .ant-modal-title {
      display: none;
    }

    .list-cate {
      ul {
        width: 100%;
        text-align: center;

        li {
          width: auto;
          padding: 1rem 0;
          font-weight: 500;

          .active {
            position: relative;

            &::after {
              position: absolute;
              top: 50%;
              right: -10rem;
              content: '✓';
              font-size: 2rem;
              font-weight: 500;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}

.btn-upload-image {
  margin: 0 !important;
}

.set-block {
  display: block;
}

.set-none {
  display: none;
}

.table2-6 {
  width: 100%;
  padding: 2rem;

  tbody {
    tr {
      td {
        padding-left: 15px;

        @media screen and (max-width: 767px) {
          font-size: 12px;
          padding-left: 8px;
          padding-right: 10px;
        }
      }
    }
  }

  .img-product2-6 {
    width: 20%;
    padding: 2rem;

    @media screen and (max-width: 767px) {
      padding: 0.5rem;
      width: 25%;
    }
  }
}

.list-color-title {
  margin-top: 2rem;
  padding-top: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease;
}

.list-color {
  max-width: 36rem;
  height: 9rem;
  padding-bottom: 1.5rem;
  @include flexbox(flex, flex-start, center, nowrap);
  transition: all 0.3s ease;
  overflow: auto hidden;

  @include mq_min_1024 {
    max-width: 30rem;
  }
  .wrap-color-item {
    min-width: calc((100% / 6));
    &:first-child {
      margin-left: auto;
    }
    &:last-child {
      margin-right: auto;
    }

    .color-item {
      position: relative;
      max-width: 4rem;
      min-width: 4rem;
      max-height: 4rem;
      min-height: 4rem;
      margin: 0 auto;
      border: 2px solid #ddd;
      border-radius: 50%;
      transition: all 0.3s ease;
      cursor: pointer;
      @include mq_min_1024 {
        max-width: 3.2rem;
        min-width: 3.2rem;
        max-height: 3.2rem;
        min-height: 3.2rem;
      }
      &:hover {
        // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
        //   rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
        // filter: drop-shadow(0 0 5px rgb(0, 0, 0));
        box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 10px;
      }

      p {
        width: 150%;
        position: absolute;
        bottom: -26px;
        left: 50%;
        font-size: 1.2rem;
        transform: translateX(-50%);
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: nowrap;
      }
    }

    .active {
      // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      //   rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
      // filter: drop-shadow(0 0 5px rgb(0, 0, 0));
      box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 10px;
    }
  }

  .Yellow {
    background-color: #ffff76;
  }

  .Red {
    background-color: #ff7575;
  }

  .Green {
    background-color: #64c664;
  }

  .Blue {
    background-color: #7a7af6;
  }

  .Orange {
    background-color: #ffcc6e;
  }
}

.wrap-orders {
  padding: 0 2rem;
  .border-image {
    padding: 2.5rem 0;
    border: 1px solid #cccccc;
    border-bottom: 0;
    @include mq_min_1024 {
      padding: 0;
      border-bottom: 1px solid #cccccc;
      border-right: none;
    }
    img {
      max-width: 20rem;
      margin: 0 auto;
      @include mq_min_1024 {
        max-width: 32rem;
        margin: 0;
        padding: 6rem;
      }
    }
  }

  @include mq_min_1024 {
    @include flexbox(flex, left, normal, nowrap);
  }
}

.preview-products {
  min-height: calc(100vh - 26rem);
  padding: 2rem 1.5rem;
  @include mq_min-768 {
    @include flexbox(flex, space-between, flex-start, nowrap);
  }
  .block-image-preview {
    img {
      margin: 3rem auto;
      width: 20rem;
      height: 20rem;
      @include mq_min-768 {
        width: 40rem;
        height: 40rem;
      }
    }
    .description {
      padding: 1rem 0;
      text-align: center;
      @include font(1.3rem, 400, 1.6);
      @include mq_min_768 {
        padding: 3rem 0;
      }
      @include mq_min_1024 {
        max-width: 100rem;
        font-size: 1.5rem;
      }
    }
  }
  .details {
    padding: 0 2rem 2rem 2rem;
    @include flexbox(flex, space-between, normal, nowrap);
    @include mq_min_768 {
      display: block;
      width: 25%;
    }
    @include mq_min_1024 {
      width: 20%;
    }

    ul {
      width: calc((100% / 2) - 2rem);
      text-decoration: dotted;
      @include mq_min_768 {
        width: 100%;
      }
      li {
        margin: 1rem 0;
        @include font(1.4rem, 500, 1.8);
        list-style: disc;
        &:first-child {
          @include font(1.6rem, 500, 1);
          margin-left: -2rem;
          margin-bottom: 2rem;
          list-style: none;
        }
        span {
          margin-left: 1rem;
          @include font(1.3rem, 400, 1.8);
        }
      }
      @include mq_min-768 {
        &:last-child {
          margin-top: 5rem;
        }
      }
    }
  }
}

.list-product-by-tag {
  margin: 3rem auto;
  @include flexbox(flex, center, normal, wrap);
  .product-item-tag {
    width: calc((100% / 2) - 2rem);
    margin: 1rem;
    padding: 2rem;
    background-color: #dad8d8;
    border-radius: 10px;
    cursor: pointer;
    @include mq_min_1024 {
      width: calc((100% / 3) - 4rem);
      margin: 2rem;
    }
    .image-product {
      padding: 1rem;
      img {
        margin: 0 auto;
        width: 12rem;
        @include mq_min_768 {
          width: 20rem;
        }
        @include mq_min_1024 {
          width: 22rem;
        }
      }
    }
    .name-product {
      margin-top: 0.5rem;
      @include font(1.4rem, 500, 1);
      text-align: center;
      @include mq_min_768 {
        font-size: 1.8rem;
      }
    }
  }
}

.list-sub-style {
  margin-top: 1rem;
  padding: 0 1rem;
  .ant-collapse-content-box {
    padding: 0 !important;
  }
  .list-color {
    max-width: 36rem;
    height: 8rem;
    @include flexbox(flex, flex-start, center, nowrap);
    transition: all 0.3s ease;
    overflow: auto hidden;

    @include mq_min_1024 {
      max-width: 30rem;
    }
    .wrap-color-item {
      min-width: calc((100% / 6));
      &:first-child {
        margin-left: auto;
      }
      &:last-child {
        margin-right: auto;
      }

      .color-item {
        position: relative;
        max-width: 4rem;
        min-width: 4rem;
        max-height: 4rem;
        min-height: 4rem;
        margin: 0 auto;
        border: 2px solid #ddd;
        border-radius: 50%;
        transition: all 0.3s ease;
        cursor: pointer;
        @include mq_min_1024 {
          max-width: 3.2rem;
          min-width: 3.2rem;
          max-height: 3.2rem;
          min-height: 3.2rem;
        }
        &:hover {
          // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
          //   rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
          // filter: drop-shadow(0 0 5px rgb(0, 0, 0));
          box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 10px;
        }

        p {
          width: 150%;
          position: absolute;
          bottom: -26px;
          left: 50%;
          font-size: 1.2rem;
          transform: translateX(-50%);
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          white-space: nowrap;
        }
      }

      .active {
        /// box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
        //   rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
        // filter: drop-shadow(0 0 5px rgb(0, 0, 0));
        box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 10px;
      }
    }
  }
}

.list-text-style {
  margin: 2.5rem 1rem 0;
  // .ant-collapse-header-text {
  //   margin-left: 24px !important;
  //   order: -1;
  // }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.role-account {
  p {
    @include flexbox(flex, flex-start, center, nowrap);
    .role {
      margin-left: 1rem;
      padding: 0.5px 6px;
      font-size: 12px;
      font-style: italic;
      border-radius: 5px;
    }
    .free {
      background-color: #d8ec21;
    }
    .business {
      background-color: #90ff7a;
    }
  }
}

.list-item-option {
  width: 32.6rem;
  height: 66.2rem;
  background-color: #fff;
  padding: 5rem 0 4rem;
  ul {
    li {
      position: relative;
      padding: 0.6rem 0;
      transition: all 0.3s ease;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
    .active {
      &::after {
        position: absolute;
        content: '✓';
        top: 0;
        right: 40px;
      }
    }
  }
}

.list-fonts {
  @include flexbox(flex, center, normal, nowrap);
  margin-top: 1rem;
  img {
    width: 4.5rem;
    height: 4.5rem;
    padding: 0.5rem;
    border: 1px solid #cccccc;
    border-radius: 6px;
    cursor: pointer;
    &:last-child {
      margin-left: 1rem;
    }
  }
  .active {
    border-color: #393939;
  }
}

.wrap-text {
  padding: 1.5rem;
  text-align: center;
  &:not(:last-child) {
    border-bottom: 2px dotted #d5d5d5;
  }
  .list-color {
    margin-top: 1rem;
  }
}

.modal-list-item {
  text-align: center;
  .ant-modal-content {
    height: 90vh;
    padding: 5rem 0;
    li {
      position: relative;
      padding: 0.8rem;
    }
    &::before {
      display: none;
    }
    .active {
      &::after {
        position: absolute;
        content: '✓';
        top: 0;
        right: 40px;
      }
    }
  }
}


.btn-dowload-flat {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
  padding: .8rem;
  border-radius: 50%;
  border: 1px solid #797979;
  background-color: #fff;
}

.color-base {
  position: relative;
  &:hover::after {
    position: absolute;
    content: "本体色";
    top: -10px;
    left: 0px;
    padding: 0 5px;
    font-size: 10px;
    color: #000;
    border-radius: 5px;
    background-color: #b0ffb7;
    border: 1px solid #e1e1e1;
    white-space: nowrap;
    @include mq_min_1024 {
      left: -6px;
    }
  }
}

.ant-select-selection-item {
  width: 6.5rem;
}